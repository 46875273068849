import { Component, OnInit , Inject} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {CallService} from '../../../services/call.service';
import {NotificationService} from '../../../services/notification.service';
import { Validators, FormGroup, FormControl } from '@angular/forms';
import {AuthService} from '../../../services/auth.service';

@Component({
  selector: 'app-create-popup',
  templateUrl: './create-popup.component.html',
  styleUrls: ['./create-popup.component.css']
})
export class CreatePopupComponent implements OnInit {

  provinces: any[]= [] ;
  form: FormGroup ;
  corregimientos: any[]= [] ;

  constructor(public auth : AuthService, private notify : NotificationService,private call : CallService, 
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<CreatePopupComponent>) { 
      this.form = new FormGroup({
        //lastname: new FormControl('', Validators.required),
        //name: new FormControl('', Validators.required),
        //phone: new FormControl('', Validators.required),
        adress: new FormControl('', Validators.required),
        province : new FormControl('', Validators.required),
       // corregimiento : new FormControl('', Validators.required)
      });
    }

  ngOnInit(): void {
    
    this.call.get(this.call.HOST + "/province").subscribe( provinces =>{
      this.provinces = provinces.result;
    }, err =>{
      this.notify.showError(err);
    });

  }

  onProvinceChange(province:any){

    this.corregimientos = [];
    this.corregimientos = this.provinces[this.provinces.findIndex(t => t.id == province)].corregimientos;

  }


  create(){

    const data = this.form.value;
    data.customer = { id: Number(this.auth.getCustomer()) } ;
    data.status = 1;
    //data.corregimiento = {id: Number(data.corregimiento)}
    data.province = {id: Number(data.province) };
    data.province = {id: 8 }; // ESTO ESTA FIJO
    
    this.call.post(data, this.call.HOST + "/adress").subscribe(res =>{
        this.notify.showSuccess("Creada correctamente");
        this.close();
    }, err=>{
      this.notify.showError(err);
    })
    
  }

  close(){
    this.dialogRef.close();
  }

}
