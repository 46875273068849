import { Component, OnInit } from '@angular/core';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import {AuthService} from '../../services/auth.service';
import {NotificationService} from '../../services/notification.service';
import {CallService} from '../../services/call.service';
import { TableService } from '../../services/pager';
import { PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import {CreatePopupComponent} from './create-popup/create-popup.component';
import {EditPopupComponent} from './edit-popup/edit-popup.component';
import {SpinnerService} from '../../services/spinner.service';


@Component({
  selector: 'app-addresses',
  templateUrl: './addresses.component.html',
  styleUrls: ['./addresses.component.css']
})
export class AddressesComponent implements OnInit {

  displayedColumns: string[] = [ 'adress' ,'phone', 'status','actions'];
  dataSource: any;

  constructor(private spinner : SpinnerService,private dialog: MatDialog,private call : CallService,public auth : AuthService, private notify : NotificationService,public  tableService: TableService<any>) {
    
   }

  ngOnInit(): void {
    this.tableService.pager.pageSize = 10 ;
    this.list();
  }

  list(event?: PageEvent){

    this.spinner.open();

    let httpParams = this.call.buildRequestParams(this.tableService.sort,"m",
    {pageIndex: event ? event.pageIndex : this.tableService.pager.pageIndex , pageSize: event ? event.pageSize : this.tableService.pager.pageSize});

    // LISTAMOS LAS DIRECCIONES DEL USUARIO
    this.call.get(this.call.HOST + "/adress/customer/"+ this.auth.getCustomer(), httpParams).subscribe(response =>{
      // CAMBIAMOS EL ESTARTUS A UNO MANEJABLE POR EL USUARIO
      for(let adress of response.result){
        adress.status = adress.status == 1 ? "Activo" : "Inactivo";
      }

      this.dataSource = new MatTableDataSource<any>(response.result);
      this.tableService.pager = response.pager;
      this.tableService.selection.clear();
      this.spinner.close();

    });

  }

  add(){

    let dialogRef: MatDialogRef<any> = this.dialog.open(CreatePopupComponent, {
      width: '50%',
      disableClose: true,
      data: { }
    })
      dialogRef.afterClosed()
      .subscribe(res => {
          window.location.reload();
      })

  }

  update(row : any){
  
    let dialogRef: MatDialogRef<any> = this.dialog.open(EditPopupComponent, {
      width: '50%',
      disableClose: true,
      data: { row : row }
    })
      dialogRef.afterClosed()
      .subscribe(res => {
       // window.location.reload();
       this.list();
      })
  }

  delete(row : any){

    this.call.delete(this.call.HOST + "/adress/" + row.id).subscribe( res =>{
        //window.location.reload();
        this.notify.showSuccess("Eliminada correctamente");
        this.list();
    }, err =>{
      this.notify.showError(err);
    })

  }

}
