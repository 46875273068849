import { Component, OnInit , Inject} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {CallService} from '../../../services/call.service';
import {NotificationService} from '../../../services/notification.service';
import { Validators, FormGroup, FormControl } from '@angular/forms';
import {AuthService} from '../../../services/auth.service';

@Component({
  selector: 'app-edit-popup',
  templateUrl: './edit-popup.component.html',
  styleUrls: ['./edit-popup.component.css']
})
export class EditPopupComponent implements OnInit {

  provinces: any[]= [] ;
  form: FormGroup ;
  corregimientos: any[]= [] ;

  constructor(public auth : AuthService, private notify : NotificationService,private call : CallService, 
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<EditPopupComponent>,) { 
      this.form = new FormGroup({
        id: new FormControl(this.data.row.id, Validators.required),
        //lastname: new FormControl('', Validators.required),
        //name: new FormControl('', Validators.required),
        //phone: new FormControl('', Validators.required),
        adress: new FormControl(this.data.row.adress, Validators.required),
        province : new FormControl(this.data.row.province.id, Validators.required),
       // corregimiento : new FormControl(this.data.row.corregimiento.id, Validators.required)
      });
      

    }


  ngOnInit(): void {
   /* this.call.get(this.call.HOST + "/province").subscribe( provinces =>{
      this.provinces = provinces.result;
      this.onProvinceChange(this.data.row.province.id);
    }, err =>{
      this.notify.showError(err);
    }); */
  }

  onProvinceChange(province:any){

    this.corregimientos = [];
    this.corregimientos = this.provinces[this.provinces.findIndex(t => t.id == province)].corregimientos;

  }

  update(){
    const data = this.form.value;
    data.customer = { id: Number(this.auth.getCustomer()) } ;
    data.status = 1;
   // data.corregimiento = {id: Number(data.corregimiento)}
    data.province = {id: Number(data.province) };
   data.province = {id: 8 };
  
    this.call.put(data, this.call.HOST + "/adress/" + data.id ).subscribe(res =>{
        this.notify.showSuccess("Actualizada correctamente");
        this.close();
    }, err=>{
      this.notify.showError(err);
    })
    
  }
  close(){
    this.dialogRef.close();
  }

}
