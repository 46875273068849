import { Component, OnInit ,Inject} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {CallService} from '../../../services/call.service';

@Component({
  selector: 'app-fac-popup',
  templateUrl: './fac-popup.component.html',
  styleUrls: ['./fac-popup.component.css']
})
export class FacPopupComponent implements OnInit {

  responseForm : any;

  constructor(
    private call : CallService, 
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<FacPopupComponent>,
  ) { }

  ngOnInit(): void {
    this.responseForm = this.data.form
  }

  exit() {
    this.call.get(this.call.HOST + "/hasplan/" + this.data.id).subscribe(chp =>{
      if(chp.result.status_customerPlan  != 4){
          this.dialogRef.close(chp.status_customerPlan);
      }
      
    })
  }

}
