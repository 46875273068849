import { Component, OnInit } from '@angular/core';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import {AuthService} from '../../services/auth.service';
import {NotificationService} from '../../services/notification.service';
import {CallService} from '../../services/call.service';
import { TableService } from '../../services/pager';
import { PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import {CreateTdcComponent} from './create-tdc/create-tdc.component';
import {SpinnerService} from '../../services/spinner.service';

@Component({
  selector: 'app-creditcards',
  templateUrl: './creditcards.component.html',
  styleUrls: ['./creditcards.component.css']
})
export class CreditcardsComponent implements OnInit {

  displayedColumns: string[] = ['maskCreditCard', 'expMonthCreditCard', 'expMonth' ,'expYearCreditCard', 'nameHolderCreditCard', 'createdAt', 'estatus','actions'];
  dataSource: any;
  tarjetas : any = [];
  items: Array<any> = [];

  constructor(private spinner : SpinnerService,private dialog: MatDialog,private call : CallService,public auth : AuthService, private notify : NotificationService,public  tableService: TableService<any>) {
  }

  ngOnInit(): void {
    this.tableService.pager.pageSize = 10 ;
    this.getTdc();
  }

  getTdc(){
    this.spinner.open();
    this.items = [];
    this.call.get(this.call.HOST + '/customer/' + this.auth.getCustomer() + '/creditcard').subscribe(params =>{
      if(params['result'] != null){
        this.tarjetas = params['result'];
        ///Por cada tarjeta

        for(let i in this.tarjetas){
          let tdc = this.tarjetas[i].creditCard;
          //Se verifica la integridad de la tdc chequeando si posee un token en HecBillingCustomer
          this.call.get(this.call.HOST + '/customer/' + this.auth.getCustomer() + '/creditcard').subscribe(params => {
            //Si la tarjeta esta correctamente referenciada
            if(params['result'] != null) {
              switch(tdc.expMonthCreditCard){
                case 1 : tdc.expMonth = "ENERO";
                break;
                case 2 : tdc.expMonth = "FEBRERO";
                break;
                case 3 : tdc.expMonth = "MARZO";
                break;
                case 4 : tdc.expMonth = "ABRIL";
                break;
                case 5 : tdc.expMonth = "MAYO";
                break;
                case 6 : tdc.expMonth = "JUNIO";
                break;
                case 7 : tdc.expMonth = "JULIO";
                break;
                case 8 : tdc.expMonth = "AGOSTO";
                break;
                case 9 : tdc.expMonth = "SEPTIEMBRE";
                break;
                case 10 : tdc.expMonth = "OCTUBRE";
                break;
                case 11: tdc.expMonth = "NOVIEMBRE";
                break;
                case 12 : tdc.expMonth = "DICIEMBRE";
                break;
              }
              
              //Se carga el nombre del proveedor TDC en la columna indicada
              tdc.expMonthCreditCard = this.tarjetas[i].providerCreditCard.description;
              //Se corta el numero enmascarado de la TDC de manera de mostrar solo los ultimos 8 digitos
              tdc.maskCreditCard = tdc.maskCreditCard.substr(tdc.maskCreditCard.length-8);
              //Se muestra el año de vencimiento de la tarjeta en el formato YYYY
              tdc.expYearCreditCard = "20" + tdc.expYearCreditCard;
              //Se busca el estatus de la TDC de manera de mostrarlo al cliente
              this.call.get(this.call.HOST + '/customer/' + this.auth.getCustomer() + '/customerHasCreditCard/' + tdc.id).subscribe(parms => {
                this.spinner.open();
                //Se guarda la descripcion del estado en la columna correspondiente
                tdc.estatus = parms['result']['statusCreditCard'] == 1 ? "Activo" : "Inactivo";
                //Se anade la TDC a la tabla correspondiente
                this.items.push(tdc);
                this.dataSource = new MatTableDataSource<any>(this.items.slice(0,11));
                this.tableService.pager.totalEntities = this.items.length ;
                this.spinner.close()

              }, (err) => {this.notify.showError(err);
                this.spinner.close(); });
            }

           
           

          }, (err) => {
            this.notify.showError(err);
            this.spinner.close();
           } );
        }

        
      }else{
        console.log("NO TDC");
        this.spinner.close();
      } 


    }, err=>{
      this.notify.showError(err);
      this.spinner.close();
    });
  }



  list(event?: PageEvent){

    let size = event ? event.pageSize : this.tableService.pager.pageSize
    let index = event ? event.pageIndex : this.tableService.pager.pageIndex;
    let itemsToShow;
    if(index == 0){
      itemsToShow = this.items.slice(0, size + 1)
    }else{
      itemsToShow = this.items.slice( (size*index), (size * (index * 2)) + 1)
    }
    
    this.tableService.pager.totalEntities = this.items.length -1;
    this.dataSource = new MatTableDataSource<any>(itemsToShow);
  }


  add(){

    let dialogRef: MatDialogRef<any> = this.dialog.open(CreateTdcComponent, {
      width: '50%',
      disableClose: true,
      data: { }
    })
      dialogRef.afterClosed()
      .subscribe(res => {
        this.getTdc();
         // window.location.reload();
      })

  }



  delete(row:any){

    this.call.get(this.call.HOST + '/customer/' + this.auth.getCustomer() + '/customerHasCreditCard/' + row.id).subscribe(tdc =>{
    
      let json = {token : tdc.result.tokenCreditCard }
      
      this.call.post(json,this.call.HOST + '/customer/' + this.auth.getCustomer() + '/creditcard/' + tdc['result'].id + '/delete').subscribe(response =>{
          this.notify.showSuccess("Procesada correctamente")
          //window.location.reload();
          this.getTdc();
        }, (err) => {
          //this.spinner.hide();
          this.notify.showError("No se pudo cambiar el estado de la tarjeta, verifique que no se encuentre asociada a un plan activo");
        })
        
      }, err =>{
        console.log(err);
       this.notify.showError(err);
      }) 
  }

}
