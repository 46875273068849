<!-- MENU DE LA APP -->
<app-menu></app-menu>

<!--Dashboard user-->
<div class="container">

<app-static-banner></app-static-banner>

<div class="dashboard">
    <div class="column-profile">
        <app-profile-menu></app-profile-menu>
    </div>
<div class="column-data">
    <div class="content-data">
    <h1>Mis Tarjetas</h1><br><br>

    <div class="m-333" fxLayout="row" style="text-align: end;">
        <!-- <span fxFlex></span> -->
        <button   mat-raised-button class="mb-8" color="basic" (click)="add()" >Agregar</button>
    </div>


    <div class="ml-8 mr-8 mt-8 pb-16">
        <div class="mat-elevation-z8">
            <mat-table class="dcf-table dcf-table-striped dcf-w-100%" [dataSource]="dataSource" matSort >

                <ng-container matColumnDef="maskCreditCard">
                    <mat-header-cell *matHeaderCellDef mat-sort-header> Número de tarjeta </mat-header-cell>
                    <mat-cell *matCellDef="let row" data-label="maskCreditCard"> {{row.maskCreditCard}} </mat-cell>
                  </ng-container>
      
                  <ng-container matColumnDef="expMonthCreditCard">
                      <mat-header-cell *matHeaderCellDef mat-sort-header> Proveedor</mat-header-cell>
                      <mat-cell *matCellDef="let row" data-label="expMonthCreditCard"> {{row.expMonthCreditCard}} </mat-cell>
                  </ng-container>
      
                  <ng-container matColumnDef="expMonth">
                      <mat-header-cell *matHeaderCellDef mat-sort-header> Mes de vencimiento </mat-header-cell>
                      <mat-cell *matCellDef="let row" data-label="expMonth"> {{row.expMonth}} </mat-cell>
                  </ng-container>
      
                  <ng-container matColumnDef="expYearCreditCard">
                      <mat-header-cell *matHeaderCellDef mat-sort-header> Año de vencimiento </mat-header-cell>
                      <mat-cell *matCellDef="let row" data-label="expYearCreditCard"> {{row.expYearCreditCard}} </mat-cell>
                  </ng-container>
      
                  <ng-container matColumnDef="nameHolderCreditCard">
                      <mat-header-cell *matHeaderCellDef mat-sort-header> Nombre </mat-header-cell>
                      <mat-cell *matCellDef="let row" data-label="nameHolderCreditCard"> {{row.nameHolderCreditCard}} </mat-cell>
                  </ng-container>
      
                  <ng-container matColumnDef="createdAt">
                      <mat-header-cell *matHeaderCellDef mat-sort-header> Fecha de creación </mat-header-cell>
                      <mat-cell *matCellDef="let row" data-label="createdAt"> {{row.createdAt}} </mat-cell>
                  </ng-container>
      
                  <ng-container matColumnDef="estatus">
                      <mat-header-cell *matHeaderCellDef mat-sort-header> Estatus </mat-header-cell>
                      <mat-cell *matCellDef="let row" data-label="estatus"> {{row.estatus}} </mat-cell>
                  </ng-container>


                <ng-container matColumnDef="actions">
                    <mat-header-cell *matHeaderCellDef mat-sort-header> Acciones </mat-header-cell>
                    <mat-cell *matCellDef="let row" data-label="estatus"> 
                        <button style="background-color: white; border: none;" mat-icon-button [matMenuTriggerFor]="msgMenu" (click)="$event.stopPropagation()" class="hidden-on-open">
                            <mat-icon>more_vert</mat-icon>
                          </button>
                        <mat-menu  #msgMenu="matMenu">
                            <button *ngIf="row.estatus=='Activo'" mat-menu-item (click)="delete(row)"> Eliminar  </button>
                            <button *ngIf="row.estatus=='Inactivo'" mat-menu-item (click)="delete(row)"> Activar  </button>
                        </mat-menu>
                    </mat-cell>
                  </ng-container>
    
            
                <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumns;" >
                </mat-row>
            </mat-table>
            
            <mat-paginator showFirstLastButtons
            [length]="tableService.pager.totalEntities"
            [pageIndex]="tableService.pager.pageIndex"
                [pageSize]="tableService.pager.pageSize"
            [pageSizeOptions]="[10, 50, 100]"
            (page)="list($event)">
            </mat-paginator>  

        </div>
        
    </div>


</div>
</div>
</div>
</div>
