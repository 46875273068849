
<!-- MENU DE LA APP -->
<app-menu></app-menu>

    <div class="container">
        <div class="banner">
          <div class="banner-left">
             <div class="banner-img">
             </div>
          </div>
          <div class="banner-right">
            <div class="banner-text">
              <h1>Conoce nuestro nuevo Wine Box, la suscripción mensual de <b > vinos </b> que<br> estabas esperando </h1>
              <p class="plan-p"> {{description}} </p>
              <div class="box-plan">
              <div class="plan-e">
                <div style="text-align: center;">
                  <img class="img-sub" style =" width: auto; height: 100px;"alt="" src="assets/image/LogoNuevo.png">
                </div>

                <p >{{ plans[0]?.description }}</p>

                <a class="button" href="#plan-essential"><b>suscríbete</b><br> ${{ plans[0]?.feeCost_plan }} mensual</a>
              </div>
              <div class="plan-s">
                <div style="text-align: center;">
                  <img class="img-sub" style =" width: auto; height: 70px; padding-bottom: 30px;"alt="" src="assets/image/logo_CVinos.png">
                </div>
               <!--   ó ${{ plans[2]?.feeCost_plan }} anual -->
                <p> {{ plans[1]?.description }}</p>
                <a class="button" href="#plan-signature"><b>suscríbete</b><br> ${{ plans[1]?.feeCost_plan }} bimensual</a>
              </div>
              </div>
            </div>
          </div>
        </div>
        <div class="mision">
        <div class="mision-left">
          <div class="mision-text" >
            <h1>Elige la suscripción de <b> vinos </b>  de tu preferencia y disfruta de beneficios adicionales.  ¡Déjate sorprender por los expertos!</h1>
          <br>
          <p>Tenemos una suscripción para cada winelover, tú eliges el ritmo y nosotros ponemos los vinos. Cada selección de vinos te hará descubrir distintas regiones y cepas, vivir nuevas emociones y sorprender a tu pareja, familiares y amigos. <br>
            <br>
            Además, podrás disfrutar de beneficios adicionales como descuentos en algunos de nuestros vinos y accesorios.
          </p>
          </div>
        </div>
        <div class="mision-right">
        </div>
        </div>

        <!-- PLANES -->
        <div class="essentials" id="plan-essential">
          <div class="essentials-text">
            <div class="e-text">
            <div class="e-text-left">
              <img alt="" src="assets/image/essential1.jpg">

              <p> El nuevo Wine Box Essentials es la suscripción mensual para entusiastas del vino que  desean explorar nuevas etiquetas. Con este plan recibirás una guía de bienvenida y, cada mes, 2 botellas de vino cuidadosamente seleccionadas por nuestro Sommelier.</p>
                <br>
                <div class="mobile-show">
              <br>
               <b> Opción 1: White & Pink (2 botellas de vino blanco o 1 botella de blanco y 1 botella de rosado) </b>
              <br>
              <br>
              <b>  Opción 2: Only Reds (2 botellas de vino tinto)  </b>

              <br>
              <br>
              <b> Opción 3: Mix (1 botella de vino blanco o rosado y 1 botella de vino tinto)   </b>


              </div>
            </div>
            <div class="e-text-right">

                <h1>Essentials</h1>

              <!-- <img alt="" src="assets/image/essential2.jpeg"> -->
            </div>
            </div>
          </div>
          <div class="essentials-plan">
            <div class="e-plan">
            <div class="t-plan">
              <div style="text-align: center;">
                <img style =" width: auto; height: 100px;"alt="" src="assets/image/LogoNuevo.png">
              </div>
          <!--   <p id="first-txt">wine box</p>
            <h2>Essentials</h2> -->




              <h2 id="price">${{ plans[0]?.feeCost_plan }}</h2>
              <div class="e-boton"><a routerLink="/subscription" [queryParams]="{option:2, plan: 713}" >White & Pink</a><a routerLink="/subscription"  [queryParams]="{option:1, plan: 713}" >Only Reds</a><a routerLink="/subscription" [queryParams]="{option:3, plan: 713}">Mix</a>
              </div>
             <div class="pc-view">
               <p>Opción 1: White & Pink (recibirás 2 botellas de vino blanco o 1 botella de blanco y 1 botella de rosado) </p>
               <p>Opción 2: Only Reds (recibirás 2 botellas de vino tinto)</p>
               <p>Opción 3: Mix (recibirás 1 botella de vino blanco o rosado y 1 botella de vino tinto)</p>
               </div>
            </div>
            </div>
          </div>
        </div>
        <div class="signature" id="plan-signature">
          <div class="signature-plan">
            <div class="s-plan">
            <div class="t-plan">
              <div style="text-align: center;">
                <img style =" width: auto; height: 70px; padding-bottom: 30px;"alt="" src="assets/image/logo_CVinos.png">
              </div>
            <!-- <p id="first-txt">club de vinos</p> -->

            <br>
            <p>${{ plans[1]?.feeCost_plan }} Bi-mensual   </p>
            <!-- ó  ${{ plans[2]?.feeCost_plan }} Anual  -->

              <div style="padding-top: 20px;" class="s-boton"><a routerLink="/subscription" [queryParams]="{option:4, plan: plans[1]?.id}" >Suscribir</a> 
              </div></div>
              <!-- <a routerLink="/subscription" [queryParams]="{option:5, plan: plans[2]?.id}">Anual</a> -->
            </div>
          </div>
          <div class="signature-text">
            <div class="s-text">
            <div class="s-text-left">
              <img alt="" src="assets/image/signature1.jpeg">
              <p>Nuestro plan original lanzado en el 2012, "Club de Vinos Signature", es la primera suscripción de vinos en Panamá, diseñada para paladares muy exigentes con vinos especiales para una experiencia fuera de serie. Si buscas vinos singulares, esta suscripción es para ti.</p>
              <h1 style="padding-top: 12vw;" >Signature</h1>
            </div>
            <div class="s-text-right">

              <!-- <img alt="" src="assets/image/signature2.jpeg"> -->
            </div>
            </div>
          </div>

        </div>


        <!-- FIN DE PLANES -->

        <div class="unete">
          <div class="unete-w">
          <h1>Suscríbete con nosotros para que nunca falten los buenos vinos en tu mesa.</h1>
    <br><br>
            <!-- <img alt="" src="assets/image/felipe_motta_.png"> -->
          <a class="button" href="#plan-essential"><b>únete</b></a>
          </div>
        </div>

        <!-- FIN SECCION UNETE -->

        <!-- RESUMEN DE PLANES-->
        <div class="image-plan">
          <div class="two-column">
            <div class="column-a">
              <img src="assets/image/plan-essential.jpg">
              <h1>plan essentials<br>${{ plans[0]?.feeCost_plan }} </h1>
              <p>mensual</p>
              <h1>plan signature<br>${{ plans[1]?.feeCost_plan }} &nbsp;&nbsp;   </h1>
              <p>bi-mensual &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  </p>
             </div>
            <div class="column-b">

              <!--  ó  &nbsp;&nbsp;  ${{ plans[2]?.feeCost_plan }} 
              anual-->

            </div>
          </div>
        </div>
        <!-- FIN DE SECCION RESUMEN DE PLANES -->


    <!-- COMPONENTE PARA LISTADO DE VINOS - SERIA EL MAS RECIENTES - MAS VENDIDOS 
    <app-wine-catalog></app-wine-catalog> -->

    <!--login-->
    <app-login></app-login>
    <!--end-->
    </div>
