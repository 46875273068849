<!-- MENU DE LA APP -->
<app-menu></app-menu>

<!--Dashboard user-->
<div class="container">
  
  <app-static-banner></app-static-banner>

<div class="dashboard">

    <div class="column-profile">
        <app-profile-menu></app-profile-menu>
    </div>

    <div class="column-data">
        <div class="content-data">
        <h1>Mis Suscripciones</h1><br><br>


        <div class="ml-8 mr-8 mt-8 pb-16">
          <div class="mat-elevation-z8">
              <mat-table class="dcf-table dcf-table-striped dcf-w-100%" [dataSource]="dataSource" matSort >
  
                <ng-container matColumnDef="plan.title">
                  <mat-header-cell  *matHeaderCellDef mat-sort-header> Producto </mat-header-cell>
                  <mat-cell   *matCellDef="let row" data-label="plan.title"> {{row.plan.title}} </mat-cell>
                </ng-container>
    
                <ng-container matColumnDef="plan.feeCost_plan">
                    <mat-header-cell   *matHeaderCellDef mat-sort-header> Precio</mat-header-cell>
                    <mat-cell  *matCellDef="let row" data-label="plan.feeCost_plan"> ${{row.plan.feeCost_plan}} </mat-cell>
                </ng-container>
    
                <ng-container matColumnDef="plan.frecuency.name_frecuency">
                    <mat-header-cell  *matHeaderCellDef mat-sort-header> Frecuencia </mat-header-cell>
                    <mat-cell  *matCellDef="let row" data-label="plan.frecuency.name_frecuency"> {{row.plan.frecuency.name_frecuency}} </mat-cell>
                </ng-container>
    
                <ng-container matColumnDef="next_bill_date">
                    <mat-header-cell  *matHeaderCellDef mat-sort-header> Proximo cobro </mat-header-cell>
                    <mat-cell  *matCellDef="let row" data-label="next_bill_date"> {{row.next_bill_date}} </mat-cell>
                </ng-container>

                <ng-container matColumnDef="status_customerPlan">
                  <mat-header-cell *matHeaderCellDef mat-sort-header> Estatus</mat-header-cell>
                  <mat-cell  *matCellDef="let row" data-label="status_customerPlan"> {{row.status_customerPlan}} </mat-cell>
              </ng-container>
    
    

                <ng-container matColumnDef="actions">
                    <mat-header-cell  *matHeaderCellDef mat-sort-header> Acciones </mat-header-cell>
                    <mat-cell *matCellDef="let row" data-label="estatus"> 
                        <button style="background-color: white;" mat-icon-button [matMenuTriggerFor]="msgMenu" (click)="$event.stopPropagation()" class="hidden-on-open">
                            <mat-icon class="text-muted">more_vert</mat-icon>
                          </button>
                        <mat-menu style="width: 200px;" #msgMenu="matMenu">
                            <button  mat-menu-item (click)="update(row)"> Actualizar </button>
                        </mat-menu>
                    </mat-cell>
                  </ng-container>
      
              
                  <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                  <mat-row *matRowDef="let row; columns: displayedColumns;" >
                  </mat-row>
              </mat-table>
              
              <mat-paginator showFirstLastButtons
              [length]="tableService.pager.totalEntities"
              [pageIndex]="tableService.pager.pageIndex"
                  [pageSize]="tableService.pager.pageSize"
              [pageSizeOptions]="[10, 50, 100]"
              (page)="list($event)">
              </mat-paginator>  
  
          </div>
          
      </div>

           


      </div>
    </div>
</div>
</div>

