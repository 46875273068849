import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AddressesComponent } from './views/addresses/addresses.component';
import { ConfirmationComponent } from './views/confirmation/confirmation.component';
import { CreditcardsComponent } from './views/creditcards/creditcards.component';
import { HomeComponent } from './views/home/home.component';
import { MySubscriptionsComponent } from './views/my-subscriptions/my-subscriptions.component';
import { NotFoundComponent } from './views/not-found/not-found.component';
import { ProfileComponent } from './views/profile/profile.component';
import { ResetComponent } from './views/reset/reset.component';
import { SubscriptionComponent } from './views/subscription/subscription.component';
import { UnauthorizedComponent } from './views/unauthorized/unauthorized.component';
import { AuthGuard } from './guards/auth.guard';

const routes: Routes = [
  //{ path: '',   redirectTo: '/home', pathMatch: 'full' }, // redirect to 
  { path: '', component: HomeComponent },
  { path: 'subscription', component: SubscriptionComponent }, 
  { path: 'profile', canActivate: [AuthGuard], component: ProfileComponent },
  { path: 'my-subscriptions', canActivate: [AuthGuard], component: MySubscriptionsComponent },
  { path: 'addresses', canActivate: [AuthGuard], component: AddressesComponent },
  { path: 'my-cards', canActivate: [AuthGuard], component: CreditcardsComponent },
  { path: 'reset', canActivate: [AuthGuard],  component: ResetComponent }, 
  { path: 'confirmation', canActivate: [AuthGuard], component: ConfirmationComponent },
  { path: 'unauthorized', component: UnauthorizedComponent },
  { path: '**', component: NotFoundComponent },  // Wildcard route for a 404 page
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
