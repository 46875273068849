<!-- MENU DE LA APP -->
<app-menu></app-menu>

<div class="container">
            <div class="text-head">
                <h1>Recibirás tus vinos acorde al plan elegido.</h1>
            </div>
            <div class="content-plan">
                <div class="plan-select">
                    <div class="column-plan">
                        <div class="column-plan-a">
                            <h1><b>1</b> Plan Escogido</h1>
                            <p>{{ detail }}</p>

                            <p>
                                Puedes "congelar tu suscripción" cuando desees y por el tiempo que desees.
                                Tu tarjeta no será cobrada y no recibirás vinos mientras tu suscripción esté congelada.
                                Si no deseas continuar con tu suscripción, podrás cancelarla en la sección de “mi cuenta” .
                            </p>
                        </div>
                        <div class="column-plan-b">
                            <img src="assets/image/Screen Shot 2022-02-10 at 9.59.22 AM.png">
                            <img src="assets/image/pinar-kucuk-w4C71BKJ6ko-unsplash.png">
                            <img src="assets/image/Screen Shot 2022-02-10 at 10.04.11 AM.png">
                            <div class="txt-right">
                                <h1>{{ title }}</h1>
                                <a>{{ option }}</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    <div class="content-plan">
        <div class="content-form">
                <div class="process">
                    <h1><b>2</b> Método de Pago  </h1><hr><h1><b>3</b> Confirmación    </h1>
                </div>
                <div class="rendered-form">
                 
                    <form [formGroup]="form"  autocomplete="off" >

                        <div *ngIf="auth.isLoggedIn()" class="row">

                            <div class="form-control">
                                  <div style="text-align: end;">
                                    <button  mat-raised-button class="mb-8" color="primary" (click)="addressesPopUp({})" >Mis direcciones</button>
                                  </div>
                            </div>
                            
                        </div>
           

                    <div class="two-column-form">
                       <!--  <div class="form-group field-mes-exp">
                            <label for="mes-exp"></label>
                            <select  style="width: 100%;" (change)="onProvinceChange($event.target.value)" [formControl]="form.controls['province']" class="form-control" name="mes-exp" >
                                    <option disabled="" selected="" value="">Provincia</option>
                                    <option *ngFor="let p of provinces" [value]="p.id" >{{p.name}}</option>

                            </select>
                            <small
                            *ngIf="form.controls['province'].hasError('required') && form.controls['province'].touched" 
                            class="form-error-msg"> Provincia es requerido </small>
                        </div>
                    <div class="form-group field-ano-exp">
                            <label for="ano-exp"></label>
                            <select style="width: 100%;" (change)="onCorregimientoChange()"  [formControl]="form.controls['corregimiento']"  class="form-control" name="ano-exp" >
                                    <option disabled="" selected="" value="">Corregimiento</option>
                                    <option *ngFor="let c of corregimientos" [value]="c.id" >{{c.name}}</option>
                                  
                            </select>

                            <small
                            *ngIf="form.controls['corregimiento'].hasError('required') && form.controls['corregimiento'].touched" 
                            class="form-error-msg"> Corregimiento es requerido </small>
                    </div> -->


                    <div class="form-group field-mes-exp">
                        <label for="mes-exp"></label>
                        <select autocomplete="address-level2"  style="width: 100%;" [formControl]="form.controls['province']" class="form-control" name="mes-exp" >
                                <option disabled="" selected="" value="">Provincia</option>
                                <option  [value]="8" >Panamá</option>

                        </select>
                        
                    </div>

                    
                    <div class="form-group field-ano-exp">

                        <input autocomplete="mobile"  [ng2TelInputOptions]="configuration" [ngModelOptions]="{standalone: true}" style="width: 100%;" (hasError)="hasError($event)"  [(ngModel)]="phone"  (countryChange)="onCountryChange($event)" (intlTelInputObject)="telInputObject($event)" (ng2TelOutput)="getNumber($event)" [ng2TelInputOptions]="options" type="text" ng2TelInput /> 
 
                       
                        <small
                        *ngIf="!errorNumber" 
                        class="form-error-msg"> Número de teléfono inválido </small>
                    </div>
                    </div>

                    <div class="form-group-solo field-direccion">
                        <input style="width: 96%;" autocomplete="address-level1"  [formControl]="form.controls['adress']" type="text" placeholder="Dirección" class="form-control one-c" name="direccion" access="false" id="direccion" required="required" aria-required="true">
                        <small
                        *ngIf="form.controls['adress'].hasError('required') && form.controls['adress'].touched" 
                        class="form-error-msg"> Dirección es requerido </small>
                     </div>


                        <div class="card">
                                <h6 access="false" id="control-1373969">Tarjeta de Crédito</h6>
                            <img style="margin-left: -70px;" src="assets/image/target.new.png">
                        </div>



                        <div *ngIf="auth.isLoggedIn()" class="row">

                            <div class="form-control">
                                  <div style="text-align: end;">
                                    <button  mat-raised-button class="mb-8" color="primary" (click)="tdcPopUp({})" >Mis tarjetas</button>
                                  </div>
                            </div>
                            
                        </div>

                        
                    <div class="two-column-form">
                            <div class="form-group field-tc">
                                    <input autocomplete="cc-name" [formControl]="form.controls['name_tdc']" type="text" placeholder="Nombre del tarjeta habiente" class="form-control one-c" name="tc" access="false" id="tc" required="required" aria-required="true">
                                    <small
                                    *ngIf="form.controls['name_tdc'].hasError('required') && form.controls['name_tdc'].touched" 
                                    class="form-error-msg"> Nombre del Titular es requerido </small>
                            </div>
                            <div class="form-group field-Numero-de-Tarjeta">
                                    <input autocomplete="cc-number" [formControl]="form.controls['number_tdc']" (change)="changeTDC()" (keypress)="onlyNumberKey($event)" type="text" placeholder="Numero de Tarjeta" class="form-control one-c" name="Numero-de-Tarjeta" access="false" id="Numero-de-Tarjeta" required="required" aria-required="true">
                                    <small
                                    *ngIf="form.controls['number_tdc'].hasError('required') && form.controls['number_tdc'].touched" 
                                    class="form-error-msg"> Número de Tarjeta es requerido </small>
                            </div>
                    </div>

                    <div class="two-column-form">
                            <div class="form-group field-mes-exp">
                                    <label for="mes-exp"></label>
                                    <select autocomplete="cc-exp-month" [formControl]="form.controls['month']" class="form-control" name="mes-exp" id="mes-exp">
                                            <option disabled="" selected="" value="">Mes de expiracion</option>
                                            <option value="01" id="mes-exp-0">Enero</option>
                                            <option value="02" id="mes-exp-1">Febrero</option>
                                            <option value="03" id="mes-exp-2">Marzo</option>
                                            <option value="04" id="mes-exp-3">Abril</option>
                                            <option value="05" id="mes-exp-4">Mayo</option>
                                            <option value="06" id="mes-exp-5">Junio</option>
                                            <option value="07" id="mes-exp-6">Julio</option>
                                            <option value="08" id="mes-exp-7">Agosto</option>
                                            <option value="09" id="mes-exp-8">Septiembre</option>
                                            <option value="10" id="mes-exp-9">Octubre</option>
                                            <option value="11" id="mes-exp-10">Noviembre</option>
                                            <option value="12" id="mes-exp-11">Diciembre</option>
                                    </select>
                            </div>
                            <div class="form-group field-ano-exp">
                                    <label for="ano-exp"></label>
                                    <select autocomplete="cc-exp-year" [formControl]="form.controls['year']"  class="form-control" name="ano-exp" id="ano-exp">
                                            <option disabled="" selected="" value="">Año de expiracion</option>
                                            <option value="22" id="ano-exp-0">2022</option>
                                            <option value="23" id="ano-exp-1">2023</option>
                                            <option value="24" id="ano-exp-2">2024</option>
                                            <option value="25" id="ano-exp-3">2025</option>
                                            <option value="26" id="ano-exp-4">2026</option>
                                            <option value="27" id="ano-exp-5">2027</option>
                                            <option value="28" id="ano-exp-6">2028</option>
                                            <option value="29" id="ano-exp-7">2029</option>
                                            <option value="30" id="ano-exp-8">2030</option>
                                            <option value="31" id="ano-exp-9">2031</option>
                                            <option value="32" id="ano-exp-10">2032</option>
                                            <option value="33" id="ano-exp-11">2033</option>
                                            <option value="34" id="ano-exp-12">2034</option>
                                            <option value="35" id="ano-exp-13">2035</option>
                                            <option value="36" id="ano-exp-14">2036</option>
                                            <option value="37" id="ano-exp-15">2037</option>
                                            <option value="38" id="ano-exp-16">2038</option>
                                            <option value="39" id="ano-exp-17">2039</option>
                                            <option value="40" id="ano-exp-18">2040</option>
                                    </select>
                            </div>
                            <div class="form-group field-CCV">
                                    <label for="CCV"></label>
                                    <input autocomplete="cc-type" [formControl]="form.controls['ccv']"  (keypress)="onlyNumberKey($event)" maxlength="3" type="text" placeholder="CCV" class="form-control" name="CCV" access="false" id="CCV">
                                    <small
                                    *ngIf="form.controls['ccv'].hasError('required') && form.controls['ccv'].touched" 
                                    class="form-error-msg"> CCV es requerido </small>
                                </div>
                    </div>

                    <div class="bot form-group field-suscribirte">
                        <button  [disabled]="form.invalid" type="submit" class="btn" name="suscribirte" access="false" id="suscribirte" (click)="checkout()" >suscribirse</button>
                        <a class="text-dark" type="button" style="cursor: pointer; display: block; padding-top: 20px;"(click)="termsPopUp({})" >Términos & condiciones</a>
                    </div>
                </form>

                
            </div>

        </div>
    </div>

    <!--login-->
    <app-login *ngIf="show" ></app-login>

</div>