<div style="text-align: end;" mat-dialog-title >
    <button  mat-raised-button mat-icon-button  class="mb-8" color="warn" (click)="close()"><mat-icon>close</mat-icon></button>
</div>
    <div  style="width: 100%;" class="column-data">
        <form  [formGroup]="form"  >
            <fieldset>

                <!-- Form Name -->
                <legend>Agregar dirección</legend>

                <!-- Text input-->
                
                <div class="two-column-form">
                    <!-- <div class="form-group field-mes-exp">
                        <label for="mes-exp"></label>
                        <select  (change)="onProvinceChange($event.target.value)" [formControl]="form.controls['province']" class="form-control" name="mes-exp" id="mes-exp">
                                <option disabled="" selected="" value="">Provincia</option>
                                <option *ngFor="let p of provinces" [value]="p.id" >{{p.name}}</option>

                        </select>
                        <small
                        *ngIf="form.controls['province'].hasError('required') && form.controls['province'].touched" 
                        class="form-error-msg"> Provincia es requerido </small>
                     </div> -->

                     <div class="form-group field-mes-exp">
                        <label for="mes-exp"></label>
                        <select [formControl]="form.controls['province']" class="form-control" name="mes-exp" id="mes-exp">
                            <option disabled="" selected="" value="">Provincia</option>
                            <option  [value]="8" >Panamá</option>
                        </select>
                        <small
                        *ngIf="form.controls['province'].hasError('required') && form.controls['province'].touched" 
                        class="form-error-msg"> Provincia es requerido </small>
                     </div> 

                <div class="form-group field-ano-exp">
                    <input style="width: 93%;" [formControl]="form.controls['adress']" type="text" placeholder="Dirección" class="form-control one-c" name="direccion" access="false" id="direccion" required="required" aria-required="true">
                    <small
                    *ngIf="form.controls['adress'].hasError('required') && form.controls['adress'].touched" 
                    class="form-error-msg"> Dirección es requerido </small>
                </div>

                <!-- <div class="form-group field-ano-exp">
                        <label for="ano-exp"></label>
                        <select [formControl]="form.controls['corregimiento']"  class="form-control" name="ano-exp" id="ano-exp">
                                <option disabled="" selected="" value="">Corregimiento</option>
                                <option *ngFor="let c of corregimientos" [value]="c.id" >{{c.name}}</option>
                              
                        </select>

                        <small
                        *ngIf="form.controls['corregimiento'].hasError('required') && form.controls['corregimiento'].touched" 
                        class="form-error-msg"> Corregimiento es requerido </small>
                </div> -->
                </div> 

               <!--  <div class="form-group-solo field-direccion">
                    <input style="width: 93%;" [formControl]="form.controls['adress']" type="text" placeholder="Dirección" class="form-control one-c" name="direccion" access="false" id="direccion" required="required" aria-required="true">
                    <small
                    *ngIf="form.controls['adress'].hasError('required') && form.controls['adress'].touched" 
                    class="form-error-msg"> Dirección es requerido </small>
                </div>  -->


                <!-- Text input
                <div class="form-group">
                <label class="col-md-4 control-label" for="number">Teléfono</label>
                <div class="col-md-5">
                <input id="number" name="number" type="text" class="form-control input-md">

                </div>
                </div>-->

            <!-- Button -->
            <div   style="text-align: end; padding-top: 20px;" class="example-button-row">
               
                <button  [disabled]="form.invalid"  (click)="create()" mat-raised-button class="mb-8" color="basic">Crear</button>
                
            </div>

            </fieldset>
        </form>


    </div>
