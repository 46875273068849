<div class="menu">
    <div class="logo-left">
    <a href="/"><img alt="logo-felipe-motta" src="assets/image/logo-fm.png" id="logo-fm" /></a>
    </div>
    <div class="logo-right">
    <img alt="logo-wine" src="assets/image/logo-wine.png" id="logo-wine" />
    </div>
</div>
<div class="menu-right">
    <button *ngIf = "!loggedIn" class="b-menu" (click)="show()" >Iniciar sesión</button>

    <button *ngIf = "loggedIn" class="b-menu"><a routerLink="/my-subscriptions">Mi Cuenta</a></button>
    <button *ngIf = "loggedIn" (click)="logout()" class="b-menu"><a >Salir</a></button>
    <!--button class="b-menu" onclick="/direcciones.html"><a href="/">Mis Direcciones</a></button>
    <button class="b-menu" onclick="/suscripcion.html"><a href="/">Mis Suscripciones</a></button>
    <button class="b-menu" onclick="/tdc.html"><a href="/">Mis Tarjetas</a></button-->

</div>