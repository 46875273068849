import { Component, OnInit } from '@angular/core';
import {CallService} from '../../services/call.service';
import {SpinnerService} from '../../services/spinner.service';
import { environment } from '../../../environments/environment';
import { DOCUMENT } from '@angular/common';
import { Inject } from '@angular/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  public description : any;
  public plans : Array<any> = [] ;

  constructor(private call : CallService,@Inject(DOCUMENT) private document: Document, private spinner : SpinnerService) { }

  ngOnInit(): void {

    this.spinner.open();

    // @ts-ignore: Object is possibly 'null'.
    this.document.getElementById('id01')?.style.display='none';

    // BUSCAMOS EL ID DEL SERVICIO ASOCIADO AL DOMINIO DE LA PAGINA
    this.call.get(this.call.HOST + "/config/key/" + window.location.host ).subscribe(config =>{
      localStorage.setItem("service",config.result.value_config);
      this.call.get(this.call.HOST + "/ecommerce/service/" + config.result.value_config).subscribe(service =>{

        localStorage.setItem("partner",service.result.partner_id);

        // CARGAMOS LA DESCRIPCION DEL SERVICIO
        this.description = service.result.description;
        // BUSCAMOS LOS SERVICIOS DEL SERVICIO
        this.call.get(this.call.HOST + "/ecommerce/plan/service/" + service.result.id ).subscribe(plans =>{
          this.plans = plans.result;
          this.spinner.close();
        }, err =>{
          console.log(err);
          this.spinner.close();
        })
      }, err =>{
        console.log(err);
        this.spinner.close();
      });
    }, err =>{
      console.log(err);
      this.spinner.close();
    });
  }

}
