
<div id="id01" class="modal">
    <div class="container" id="container">
      <div class="form-container sign-up-container">
        <form [formGroup]="signupForm" (ngSubmit)="signup()">
          <h1>Crear cuenta</h1>
  
  
          <input id ="id02"  [formControl]="signupForm.controls['name_customer']" type="text" placeholder="Nombre" />
          <small
                      *ngIf="signupForm.controls['name_customer'].hasError('required') && signupForm.controls['name_customer'].touched" 
                      class="form-error-msg"> Nombre es requerido </small>

          <input [formControl]="signupForm.controls['lastName_customer']" type="text" placeholder="Apellido" />
          <small
                      *ngIf="signupForm.controls['lastName_customer'].hasError('required') && signupForm.controls['lastName_customer'].touched" 
                      class="form-error-msg"> Apellido es requerido </small>

        
          <input [formControl]="signupForm.controls['email_customer']" type="email" placeholder="Correo" />

          <small 
          *ngIf="signupForm.controls['email_customer'].hasError('required') && signupForm.controls['email_customer'].touched" 
          class="form-error-msg"> Email es requerido </small>

          <small 
          *ngIf="signupForm.controls['email_customer'].hasError('email') && signupForm.controls['email_customer'].touched" 
          class="form-error-msg"> Email Invalido. El formato debe ser example@dot.com </small>
         
          <button class="btn" [disabled]="signupForm.invalid" >Registrarme</button>
        </form>
      </div>
      <div class="form-container sign-in-container">
        <form  [formGroup]="signinForm" (ngSubmit)="signin()" >
          <h1>Inicia sesión con tu cuenta de Felipemotta.com</h1>
  
          <input [formControl]="signinForm.controls['username']"  type="email" placeholder="Correo" />

          <small 
          *ngIf="signinForm.controls['username'].hasError('required') && signinForm.controls['username'].touched" 
          class="form-error-msg"> Correo es requerido </small>

          <small 
          *ngIf="signinForm.controls['username'].hasError('email') && signinForm.controls['username'].touched" 
          class="form-error-msg"> Correo Invalido. El formato debe ser example@dot.com </small>

          <input [formControl]="signinForm.controls['password']" type="password" placeholder="Contraseña" />

          <small 
          *ngIf="signinForm.controls['password'].hasError('required') && signinForm.controls['password'].touched" 
          class="form-error-msg"> Contraseña es requerido </small>
          
          <a (click)="change()" style="cursor: pointer;" >¿Olvidó la contraseña?  Click <b>aquí</b>   para restablecerla. </a>

          <button class="btn" [disabled]="signinForm.invalid" >Iniciar</button>
          <button style="margin-top: 10px ;" type="button" (click)="loginWithGoogle()" class="btn btn-danger">Login with Google</button>
        </form>
          
          
      </div>
      <div class="overlay-container">
        <div class="overlay">
          <div class="overlay-panel overlay-left">
            <h1>¿Ya tienes cuenta?</h1>
            <p>Ingresa al mejor club de vinos de Panamá</p>
            <button (click)="removeClass()"  class="ghost" id="signIn">Iniciar</button>
          </div>
          <div class="overlay-panel overlay-right">
            <h1>¡Bienvenido!</h1>
            <p>Se parte del mejor club de vinos de Panamá.</p>
            <button (click)="addClass()" class="ghost" id="signUp">Registrarte</button>
          </div>
        </div>
      </div>
    </div>
  </div>