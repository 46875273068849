import { Injectable } from '@angular/core';
import { HttpParams, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import {throwError as observableThrowError,  Observable } from 'rxjs';

import { HttpClient} from '@angular/common/http';
import {CallService} from '../services/call.service';
import { map, catchError, delay } from "rxjs/operators";
import {
  SocialAuthService
} from 'angularx-social-login';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  httpOptions : any;

  APP_USER_TIME = "TIME_USER";
  APP_TIME_SESSION = 10;

  constructor(private socialAuthService : SocialAuthService, private call : CallService, private http: HttpClient) { }

  public createAuthorizationHeader(): HttpHeaders {
    if(localStorage.getItem('user') != null){
        return new HttpHeaders({
            'Authorization': <any> localStorage.getItem('token') ,
            'User' : <any> localStorage.getItem('user') ,
            'Access-Control-Allow-Origin': '*'
          });
    }else{
        return new HttpHeaders({
            'Authorization': <any> localStorage.getItem('token'),
            'Access-Control-Allow-Origin': '*'
          });
    }  
  }

  public createLoginHeader(): HttpHeaders {
      return new HttpHeaders({
          'Content-Type': 'application/x-www-form-urlencoded'
        });
  }

  // PARA VERIFICAR SESION
  public isLoggedIn(): Boolean {
    let user = false;
    if(localStorage.getItem("user") != null){
      user = true;
    }
    return user;
  }
  // PARA RETORNAR EL USUARIO
  getUser(): any{
    return localStorage.getItem("user");
  }

  // ESTO RETORNA EL ID DE USUARIO QUE TIENE EL CUSTOMER DEL LADO DE FM
  getScope(): any{
    return localStorage.getItem("scope");
  }

  // PARA RETORNAR EL CUSTOMER
  getCustomer(): any{
    return localStorage.getItem("customer");
  }

  // PARA RETORNAR EL CUSTOMER
  getPartner(): any{
    return localStorage.getItem("partner");
  }

  // PARA RETORNAR EL USUARIO
  getTime(): any{
    return localStorage.getItem(this.APP_USER_TIME);
  }
  // PARA RETORNAR EL SERVICIO
  getService(): any{
    return localStorage.getItem("service");
  }
  // PARA CERRAR LA SESION DEL USUARIO
  closeSesion(){ // REMOVEMOS TODOS LAS VARIABLES
    localStorage.removeItem("user");
    localStorage.removeItem("token");
    localStorage.removeItem("customer");
    localStorage.removeItem("service");
    localStorage.removeItem("partner");
    localStorage.removeItem("scope");
    if(Number(localStorage.getItem("google")) == 1){ // SI SE INICIO SESION CON GOOGLE ENTONCE SE CIERRA
      this.logOutGoogle();
    }
    localStorage.removeItem("google")
    localStorage.removeItem(this.APP_USER_TIME);
  }

  logOutGoogle(): void {
    this.socialAuthService.signOut();
  }

}
